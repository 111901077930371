.col-md-4
{
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.showcase-img
{
	&.tng-showcase-img-1
	{
		background-image: url("tng-garage.jpg");
	}

	&.tng-showcase-img-2
	{
		background-image: url("spacer_4.jpg");
	}

	&.tng-showcase-img-3
	{
		background-image: url("spacer_5.jpg");
	}

	&.tng-showcase-img-4
	{
		background-image: url("4-jahre-konvoi.jpg");
	}
}
